

.big {
  padding: 6px 10px 6px 10px;
  font-size: 20px;
  font-weight: 500;
}

.align-left {
  align-self:first baseline;
}


.btn-primary {
  border-color: rgb(255, 170, 0);
  background-color: rgb(255, 188, 53);
  font-weight: 750;
  color: white;
  text-decoration: none;

}

.btn-primary:hover {
  border-color: rgb(255, 200, 91);
  background-color: rgb(255, 200, 91);
  color: white;

  text-decoration: none;

}

.btn-secondary {
  border-color: rgb(255, 209, 123);
  background-color: rgb(255, 237, 201);
  color: black;
}

.btn-light {
  border: solid 1px rgb(206, 206, 206);
}

.head {
  background-color: rgba(255, 255, 255, 0.863);
  border-bottom: 7px solid rgba(221, 228, 235);
  text-align: center;
  padding-top: 20px;
}

.navOption { 
  margin: 8px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  border: none;
}

.mainTitle {
  text-decoration: none;
  color: black;
  font-size: 40px;
  font-weight: 450;
}
.mainTitle img {
  width: 100%;
}

.mainTitle:hover {
  color: black;
}

.grey {
  background-color: rgba(221, 228, 235);
}

.subDomainHeadPiece {

}

.subDomainHeadPiece h1 {
  font-size:large;
}
.subDomainHeadPiece h2 {
  font-size: medium;
}

.subDomainHeadPiece h3 {
  font-size: small;
}

.subDomainHeadPiece img {
}

.tight {
  margin: 0px;
  padding: 0px;
}

.searchBar {
  justify-content: center;
  min-height: 250px;
  height: 30vw;
}

.searchInput {
  width: 400px;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.865);
}

.blue {
  background-image: url(./lighthouse_1000.jpg);
  background-size: cover;
  background-position: 50% 90%;
}

@media (min-width: 1000px) {
  .blue {
    background-image: url(./lighthouse.jpg);
    background-size: cover;
    background-position: 50% 90%;
  }
}

.pageSubTitle {
  justify-content: center;
  padding: 35px;
  font-size: 20px;
  font-weight: 300;
}

.flat-dropdown-item:hover {
  color: black;
  background-color: white;
}


.infoModalContent {
  justify-content: start;
  align-items: center;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  overflow: "scroll";
}

.emphNumber {
  font-size: 18px;
  font-weight: 600;
}

.imageModalTop {
  justify-content: center;
}

.listing-card-body {
  height: 120px;
  color: black;
}

.listingFullContent {
  margin: 30px 5px 0px 5px;
}





.alert {
  height: 45px;
  padding: 10px;
  justify-content: center;
  text-align: center;
}

.alert {
  margin-bottom: 20px;
}

.card-header {
  height: 200px;
  background-size: cover;
  background-position: 50% 60%;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.18);
  border: none;
  
}

.card-img-top {
  background-size: cover;
  background-position: 50% 90%;
}

.card-body {
  font-size: small;
}

.card-body h1 {
  color: rgb(255, 166, 13);
  font-weight: 600;
  font-size: 16px;
  margin: 2px;

}

.card-body h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 2px;

}

.card-body h3 {
  font-size: 16px;
  margin: 2px;
}

.card-body p {
  margin: 2px;
}

.card-body img {
  margin-bottom: 10px;
}

.realtorCard {
  max-width: 350px;
  text-align: center;
}


.row {
  justify-content: center;
}

.aboutInfo {
  justify-content: center;
  align-items: center;
}

.compName {
  border-bottom: 3px solid rgb(255, 166, 13);
}

.card-body img {
  height: 250px;
}

.modal-body h1 {
  font-size: 30px;
  font-weight: 500;
  color:rgb(255, 166, 13);
}

.modal-body h3 {
  font-size: 25px;
  font-weight: 600;
}

.modal-body h4 {
  font-size: 21px;
}

